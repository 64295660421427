//
//
//  Dashboard Home
//
//

import {Container, Grid, LoadingOverlay, Title} from "@mantine/core";
import DashboardHeader from "../../../components/DashboardHeader.tsx";
import {DatePickerInput} from "@mantine/dates";
import {useEffect, useState} from "react";
import MetricCard from "../../../components/MetricCard.tsx";
import {IconMessages, IconPencil} from "@tabler/icons-react";
import {BarChart, LineChart} from "@mantine/charts";
import {API} from "../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {useTeam} from "../../../contexts/TeamContext.tsx";
import {groupAndSum, objectAverage} from "../../../utils.ts";
import {useTolgee, useTranslate} from "@tolgee/react";

function defaultDateRange(): [Date, Date] {
    const currentDate = new Date()
    const previousMonthDate = new Date()
    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1)
    return [previousMonthDate, currentDate]
}

function DashboardOverview() {
    const tolgee = useTolgee(["language"])
    const { t } = useTranslate()
    const [loading, setLoading] = useState(true)
    const {getAccessTokenSilently} = useAuth0()
    const {team} = useTeam()
    const [stats, setStats] = useState<any>([])
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(defaultDateRange())

    useEffect(() => {
        if (dateRange[0] == null && dateRange[1] == null) {
            setDateRange(defaultDateRange())
        }

        if (dateRange[0] == null || dateRange[1] == null) {
            return
        }

        setLoading(true)
        API.getStats(getAccessTokenSilently, team.id, dateRange[0], dateRange[1]).then(response => {
            setStats(response["stats"])
            setLoading(false)
        })
    }, [dateRange, getAccessTokenSilently, team.id])

    const statsWithUser = stats.flatMap((item: any) => {
        if (item.user == null) {
            return []
        }
        return item
    })

    const conversationsPerDate = groupAndSum(stats, "date", "num_conversations")
    const messagesPerDate = groupAndSum(stats, "date", "num_messages")
    const conversationsPerUser = groupAndSum(statsWithUser, ((item: any) => item.user.name), "num_conversations")
    const messagesPerUser = groupAndSum(statsWithUser, ((item: any) => item.user.name), "num_messages")

    return (
        <div>
            <DashboardHeader
                title={t("dashboard.overview.title", "Overview")}
                rightSection={
                    <DatePickerInput
                        type="range"
                        value={dateRange}
                        onChange={setDateRange}
                        locale={tolgee.getLanguage()}
                    />
                }
            />
            <Container mt={40}>
                <LoadingOverlay visible={loading}/>
                <Grid>
                    <Grid.Col span={4}>
                        <MetricCard
                            title={t("dashboard.overview.conversations", "Conversations")}
                            value={stats.map((x: any) => x.num_conversations).reduce((a: number, b: number) => a + b, 0)}
                            icon={<IconMessages size={16}/>}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <MetricCard
                            title={t("dashboard.overview.messages", "Messages")}
                            value={stats.map((x: any) => x.num_messages).reduce((a: number, b: number) => a + b, 0)}
                            icon={<IconPencil size={16}/>}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mt={40}>
                    <Grid.Col span={6}>
                        <Title order={5} mb={10}>{t("dashboard.overview.conversations", "Conversations")}</Title>
                        <LineChart
                            h={300}
                            data={conversationsPerDate}
                            dataKey="name"
                            curveType="linear"
                            lineChartProps={{ syncId: "date" }}
                            series={[
                                {
                                    "name": "value",
                                    "label": t("dashboard.overview.conversations", "Conversations"),
                                    "color": "teal.4"
                                }
                            ]}
                            referenceLines={[
                                {
                                    y: objectAverage(conversationsPerDate, "value"),
                                    color: 'teal.9',
                                    label: t("dashboard.overview.average", "Average"),
                                    labelPosition: 'insideTopRight',
                                },
                            ]}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Title order={5} mb={10}>{t("dashboard.overview.messages", "Messages")}</Title>
                        <LineChart
                            h={300}
                            data={messagesPerDate}
                            dataKey="name"
                            curveType="linear"
                            lineChartProps={{ syncId: "date" }}
                            series={[
                                {
                                    "name": "value",
                                    "color": "blue.4",
                                    "label": t("dashboard.overview.messages", "Messages"),
                                }
                            ]}
                            referenceLines={[
                                {
                                    y: objectAverage(messagesPerDate, "value"),
                                    color: 'blue.9',
                                    label: t("dashboard.overview.average", "Average"),
                                    labelPosition: 'insideTopRight',
                                },
                            ]}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Title order={5} mb={10}>{t("dashboard.overview.conversations-users", "Conversations / User")}</Title>
                        <BarChart
                            h={400}
                            data={conversationsPerUser}
                            dataKey="name"
                            orientation="vertical"
                            series={[
                                {
                                    "name": "value",
                                    "color": "teal.4",
                                    "label": t("dashboard.overview.conversations", "Conversations")
                                },
                            ]}
                            referenceLines={[
                                {
                                    x: objectAverage(conversationsPerUser, "value"),
                                    color: 'teal.9',
                                    label: t("dashboard.overview.average", "Average"),
                                    labelPosition: 'insideTopRight',
                                },
                            ]}
                            barChartProps={{ syncId: "user" }}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Title order={5} mb={10}>{t("dashboard.overview.messages-user", "Messages / User")}</Title>
                        <BarChart
                            h={400}
                            data={messagesPerUser}
                            dataKey="name"
                            orientation="vertical"
                            series={[
                                {
                                    "name": "value",
                                    "color": "blue.4",
                                    "label": t("dashboard.overview.messages", "Messages"),
                                },
                            ]}
                            referenceLines={[
                                {
                                    x: objectAverage(messagesPerUser, "value"),
                                    color: 'blue.9',
                                    label: t("dashboard.overview.average", "Average"),
                                    labelPosition: 'insideTopRight',
                                },
                            ]}
                            barChartProps={{ syncId: "user" }}
                        />
                    </Grid.Col>
                </Grid>
            </Container>
        </div>
    )
}

export default DashboardOverview
