//
//
//  Home
//
//


import {useTeam} from "../../contexts/TeamContext.tsx";
import {Navigate} from "react-router-dom";


function Home() {
    const {team} = useTeam()

    if (team.role === "VIEWER") {
        return <Navigate replace to="/chat" />
    } else {
        return <Navigate replace to="/dashboard" />
    }
}

export default Home
