//
//
//  Edit Assistant Modal
//
//

import {useForm} from "@mantine/form";
import {Button, Checkbox, Flex, Textarea, TextInput} from "@mantine/core";
import {useTranslate} from "@tolgee/react";

function EditAssistantModal({assistant, onSubmit, onCancel}: {assistant: any, onSubmit: (values: any) => void, onCancel: () => void}) {
    const {t} = useTranslate()
    const form = useForm({
        initialValues: {
            title: assistant.title,
            description: assistant.description,
            enabled: !assistant.disabled
        }
    })

    function onSubmitInner(values: any) {
        const submitValues = Object.assign({}, values, {disabled: !values.enabled})
        onSubmit(submitValues)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
            <TextInput
                required
                withAsterisk
                label={t("dashboard.assistant.edit-title", "Title")}
                placeholder="Super duper"
                {...form.getInputProps("title")}
            />
            <Textarea
                mt={12}
                label={t("dashboard.assistant.description", "Description")}
                {...form.getInputProps("description")}
            />
            <Checkbox
                mt={12}
                label={t("dashboard.assistant.enabled", "Enabled")}
                {...form.getInputProps('enabled', { type: 'checkbox' })}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </Flex>
        </form>
    )
}

export default EditAssistantModal
