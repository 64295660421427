//
//
//  Assistants Selector
//
//

import {Assistant as AssistantInterface} from "../../interfaces"
import {Avatar, Button, Flex, SimpleGrid, Text, ScrollArea, LoadingOverlay, ActionIcon} from "@mantine/core";
import {useEffect, useState} from "react";
import {API} from "../../api.ts";
import {useTeam} from "../../contexts/TeamContext.tsx";
import {useAuth0} from "@auth0/auth0-react";
import classes from "./AssistantsSelector.module.css"
import {IconAssembly, IconX} from "@tabler/icons-react";
import {modals} from "@mantine/modals";
import AssistantsSelectorModal from "./AssistantsSelectorModal.tsx";
import {useTranslate} from "@tolgee/react";


function AssistantsSelector({onSelect, onDeselect}: { onSelect: (assistant: AssistantInterface) => void , onDeselect: () => void}) {
    const {t} = useTranslate()
    const [currentAssistant, setCurrentAssistant] = useState<AssistantInterface | null>(null)
    const [loading, setLoading] = useState(true)
    const {team} = useTeam()
    const {getAccessTokenSilently} = useAuth0()
    const [assistants, setAssistants] = useState([])

    function onAssistantSelect(assistant: AssistantInterface) {
        setCurrentAssistant(assistant)
        onSelect(assistant)
    }

    function onAssistantDeselect() {
        setCurrentAssistant(null)
        onDeselect()
    }

    const openAssistantsSelectorModal = () => {
        modals.open({
            title: "Select Assistant",
            scrollAreaComponent: ScrollArea.Autosize,
            children: (
                <AssistantsSelectorModal
                    assistants={assistants}
                    onSelect={(assistant: AssistantInterface) => {
                        onAssistantSelect(assistant)
                        modals.closeAll()
                    }}
                />
            )
        })
    }

    useEffect(() => {
        API.getTeamAssistants(getAccessTokenSilently, team.id)
            .then(response => {
                const availableAssistants = response.filter((assistant: AssistantInterface) => !assistant.disabled)
                setAssistants(availableAssistants)
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setLoading(false)
            })
    }, [team.id, getAccessTokenSilently])

    useEffect(() => {
        if (assistants.length == 1) {
            onSelect(assistants[0])
            setCurrentAssistant(assistants[0])
        }
    }, [assistants.length, onSelect])

    if (loading)  {
        return (
            <LoadingOverlay visible={loading}/>
        )
    }

    // Assistant selected
    if (currentAssistant) {
        return (
            <Flex direction="column" align="center" gap={18}>
                <Avatar radius="sm" size="xl">
                    <IconAssembly size={40}/>
                </Avatar>
                <Text size="xl" fw={600}>{t("assistants.welcome", "How can I help you today?")}</Text>
                <Flex direction="column" align="center">
                    <Text size="xl" fw={600} c="dimmed">{currentAssistant?.title}</Text>
                    <Text size="md" c="dimmed">{currentAssistant?.description}</Text>
                </Flex>
                {currentAssistant && assistants.length > 1 && <ActionIcon
                    onClick={() => onAssistantDeselect()}
                    variant="light"
                    color="gray"
                    size="lg"
                >
                    <IconX size={20}/>
                </ActionIcon>}
            </Flex>
        )
    }

    if (assistants.length == 0) {
        return (
            <Flex direction="column" align="center" justify="center" className={classes.wrapper}>
                <Text ta="center" size="lg" fw={500} mt={12}>{t("assistants.unavailable", "No assistants available")}</Text>
            </Flex>
        )
    }

    return (
        <Flex direction="column" className={classes.wrapper}>
            <Flex align="center" gap={20} direction="column">
                <Text size="xl" fw={600} mb={20}>{t("assistants.talk-to", "Who do you want to talk to?")}</Text>
                <SimpleGrid cols={3} className={classes.selector}>
                    {assistants.slice(0, 18).map((assistant: AssistantInterface) => {
                        return (
                            <button key={assistant.id} className={classes.assistant} onClick={() => onAssistantSelect(assistant)}>
                                <Avatar radius="sm" size="sm">
                                    <IconAssembly size={16}/>
                                </Avatar>
                                <Text size="sm">{assistant.title}</Text>
                            </button>
                        )
                    })}
                </SimpleGrid>
                <Button onClick={openAssistantsSelectorModal} variant="light" color="white">{t("assistants.more", "More")}</Button>
            </Flex>
            <Text ta="center" mt={12} fw={500} size="sm">{t("assistants.available", "{count} assistants available", {count: assistants.length})}</Text>
        </Flex>
    )
}

export default AssistantsSelector
