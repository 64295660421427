//
//
//  Owner Home
//
//

import {AppShell, Avatar, Button, useMantineColorScheme, Text, Flex, Divider, Badge} from "@mantine/core"

import classes from "./index.module.css"
import {useEffect} from "react";
import {
    IconBrain,
    IconChartLine,
    IconMessageChatbot,
    IconMessages,
    IconUsers
} from "@tabler/icons-react";
import {Link, Outlet} from "react-router-dom";
import maiaLogoImg from "../../assets/maia-logo.svg";
import ProfileMenu from "../../components/ProfileMenu.tsx";
import {useTeam} from "../../contexts/TeamContext.tsx";
import {useTranslate} from "@tolgee/react";


function Dashboard() {
    const {t} = useTranslate()
    const {team} = useTeam()
    const currentPath = window.location.pathname
    const activePath = currentPath.substring("/dashboard".length + 1).split("/")[0]

    const {setColorScheme} = useMantineColorScheme()

    useEffect(() => {
        setColorScheme("light")
    }, [])

    return (
        <AppShell
            navbar={{ width: 260, breakpoint: 'sm', collapsed: { mobile: true } }}
            padding="md"
        >
            <AppShell.Navbar className={classes.navbar} p="md">
                <Flex gap={10} justify="center" align="center" mb={16}>
                    <Avatar size="sm" radius="xl" src={maiaLogoImg}/>
                    <Text fw={600} size="xl">Knowledge Base</Text>
                </Flex>
                <Flex justify="center" align="center" mb={12}>
                    <Badge variant="outline" color="grape" size="md" radius="sm">{team.name}</Badge>
                </Flex>
                <Flex direction="column" gap={8} className={classes.navbarContent}>
                    <Button
                        variant={activePath == "" ? "filled" : "subtle"}
                        leftSection={<IconChartLine size={20}/>}
                        component={Link}
                        fullWidth
                        justify="left"
                        to="/dashboard"
                    >
                        {t("dashboard.menu.overview", "Overview")}
                    </Button>
                    <Button
                        variant={activePath == "knowledge-base" ? "filled" : "subtle"}
                        leftSection={<IconBrain size={20}/>}
                        justify="left"
                        component={Link}
                        fullWidth
                        to="/dashboard/knowledge-base"
                    >
                        {t("dashboard.menu.knowledge-base", "Knowledge Base")}
                    </Button>
                    <Button
                        leftSection={<IconMessages size={20}/>}
                        variant={activePath == "conversations" ? "filled" : "subtle"}
                        justify="left"
                        component={Link}
                        fullWidth
                        to="/dashboard/conversations"
                    >
                        {t("dashboard.menu.conversations", "Conversations")}
                    </Button>
                    <Button
                        leftSection={<IconUsers size={20}/>}
                        variant={activePath == "members" ? "filled" : "subtle"}
                        justify="left"
                        component={Link}
                        fullWidth
                        to="/dashboard/members"
                    >
                        {t("dashboard.menu.members", "Members")}
                    </Button>
                    <Button
                        variant="subtle"
                        leftSection={<IconMessageChatbot size={20}/>}
                        fullWidth
                        justify="left"
                        component={Link}
                        to="/chat"
                    >
                        {t("dashboard.menu.chat", "Chat")}
                    </Button>
                </Flex>
                <Flex direction="column">
                    <Divider mb={10}/>
                    <ProfileMenu
                        includeDashboard={false}
                        includeAdmin={true}
                    />
                </Flex>
            </AppShell.Navbar>
            <AppShell.Main style={{paddingBottom: 0}}>
                <Outlet/>
            </AppShell.Main>
        </AppShell>
    )
}

export default Dashboard
