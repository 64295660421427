//
//
//  Admin page
//
//

import {ActionIcon, AppShell, Avatar, Button, Divider, Flex, Text, useMantineColorScheme} from "@mantine/core";
import {useEffect} from "react";
import classes from "./index.module.css";
import maiaLogoImg from "../../assets/maia-logo.svg";
import {IconShieldLock, IconZoomMoney} from "@tabler/icons-react";
import {Link, Outlet} from "react-router-dom";
import ProfileMenu from "../../components/ProfileMenu.tsx";

function Admin() {
    const currentPath = window.location.pathname
    const activePath = currentPath.substring("/admin".length + 1).split("/")[0]

    const {setColorScheme} = useMantineColorScheme()

    useEffect(() => {
        setColorScheme("light")
    }, [setColorScheme])

    return (
        <AppShell
            navbar={{width: 260, breakpoint: 'sm', collapsed: {mobile: true}}}
            padding="md"
        >
            <AppShell.Navbar className={classes.navbar} p="md">
                <Link to="/" style={{"all": "unset", "cursor": "pointer"}}>
                    <Flex gap={10} justify="center" align="center" mb={16}>
                        <Avatar size="sm" radius="xl" src={maiaLogoImg}/>
                        <Text fw={600} size="xl">Knowledge Base</Text>
                    </Flex>
                </Link>
                <Flex justify="center">
                    <ActionIcon variant="subtle" radius="xl" size={48} component={Link} to="/admin">
                        <IconShieldLock size={36}/>
                    </ActionIcon>
                </Flex>
                <Flex direction="column" gap={8} className={classes.navbarContent}>
                    <Button
                        variant={activePath == "usage" ? "filled" : "subtle"}
                        leftSection={<IconZoomMoney size={20}/>}
                        component={Link}
                        fullWidth
                        justify="left"
                        to="/admin/usage"
                    >
                        Usage
                    </Button>
                </Flex>
                <Flex direction="column">
                    <Divider mb={10}/>
                    <ProfileMenu
                        includeSwitchTeam={false}
                        includeDashboard={true}
                        includeAdmin={false}
                    />
                </Flex>
            </AppShell.Navbar>
            <AppShell.Main style={{paddingBottom: 0}}>
                <Outlet/>
            </AppShell.Main>
        </AppShell>
    )
}

export default Admin
