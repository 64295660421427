//
//
//  Require Logged In
//
//

import {Outlet} from "react-router-dom"
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {TeamProvider} from "../contexts/TeamContext.tsx";

function TeamOutlet() {
    return (
        <TeamProvider>
            <Outlet/>
        </TeamProvider>
    )
}

function RequireLoggedIn() {
    const Component = withAuthenticationRequired(TeamOutlet, {
        returnTo: () => window.location.hash.substring(1),
    })
    return <Component/>
}

export default RequireLoggedIn
