//
//
//  Dashboard Card
//
//

import {ReactNode} from "react"
import classes from "./DashboardCard.module.css"

function DashboardCard({children}: {children: ReactNode}) {
    return (
        <div className={classes.wrapper}>
            {children}
        </div>
    )
}

export default DashboardCard
