//
//
//  New Article Modal
//
//


import NewArticleStart from "./NewArticleStart.tsx";
import {modals} from "@mantine/modals";
import NewArticleScratchForm from "./NewArticleScratchForm.tsx";
import NewArticleFileForm from "./NewArticleFileForm.tsx";
import NewArticleUrlForm from "./NewArticleUrlForm.tsx";
import {useTranslate} from "@tolgee/react";


function NewArticleModal({onSubmit}: {onSubmit: (values: any) => void}) {
    const {t} = useTranslate()

    return (
        <NewArticleStart
            onSubmit={(startType) => {
                if (startType === "blank") {
                    modals.open({
                        title: t("dashboard.new-article.from-scratch", "Add article from scratch"),
                        children: (
                            <NewArticleScratchForm
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else if (startType === "files") {
                    modals.open({
                        title: t("dashboard.new-article.from-files", "Add article from files"),
                        children: (
                            <NewArticleFileForm
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else if (startType === "urls") {
                    modals.open({
                        title: t("dashboard.new-article.from-urls", "Add article from urls"),
                        children: (
                            <NewArticleUrlForm
                                onSubmit={onSubmit}
                                onCancel={() => {
                                    modals.closeAll()
                                }}
                            />
                        )
                    })
                } else {
                    throw new Error("Invalid start type")
                }
            }}
        />
    )
}

export default NewArticleModal
