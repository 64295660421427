//
//
//  API
//
//

import {Conversation, Team} from "./interfaces.ts";
import {raiseForStatus, toJsonOrRaise} from "./utils.ts";

export class API {

    static async getUserTeams(getAccessTokenSilently: () => Promise<string>, name?: string, page: number = 1, pageSize: number = 25): Promise<Team[]> {
        const paramsObj: Record<string, string> = {page: page.toString(), size: pageSize.toString()}
        if (name) {
            paramsObj["name"] = name
        }
        const params = new URLSearchParams(paramsObj)
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + '/api/user/teams' + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getTeam(getAccessTokenSilently: () => Promise<string>, teamId: string | number): Promise<Team> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + '/api/teams/' + teamId, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createConversationMessage(getAccessTokenSilently: () => Promise<string>, conversationId: string | number, message: string): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/conversations/" + conversationId + "/messages", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({message})
        })
        return raiseForStatus(response)
    }

    static async getConversations(getAccessTokenSilently: () => Promise<string>, teamId: string | number, include: string = "USER", page: number = 1, pageSize: number = 25): Promise<any> {
        const params = new URLSearchParams({include, page: page.toString(), size: pageSize.toString()})
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams/" + teamId + "/conversations" + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getConversation(getAccessTokenSilently: () => Promise<string>, conversationId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/conversations/" + conversationId, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getConversationMessages(getAccessTokenSilently: () => Promise<string>, conversationId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/conversations/" + conversationId + "/messages", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createConversation(getAccessTokenSilently: () => Promise<string>, teamId: string | number, assistantId: string | number, name?: string): Promise<Conversation> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams/" + teamId + "/conversations", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, assistant_id: assistantId})
        })
        return toJsonOrRaise(response)
    }

    static async getStats(getAccessTokenSilently: () => Promise<string>, teamId: string | number, start_date: Date, end_date: Date): Promise<any> {
        const datify = (date: Date) => date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0')
        const params = new URLSearchParams({start_date: datify(start_date), end_date: datify(end_date)})
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams/" + teamId + "/stats" + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getTeamMembers(getAccessTokenSilently: () => Promise<string>, teamId: string | number, page: number = 1, pageSize: number = 25): Promise<any> {
        const params = new URLSearchParams({page: page.toString(), size: pageSize.toString()})
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams/" + teamId + "/members" + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getTeamAssistants(getAccessTokenSilently: () => Promise<string>, teamId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams/" + teamId + "/assistants", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createTeamAssistant(getAccessTokenSilently: () => Promise<string>, teamId: string | number, title: string, description: string): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/teams/" + teamId + "/assistants", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({title, description})
        })
        return toJsonOrRaise(response)
    }

    static async deleteTeamAssistant(getAccessTokenSilently: () => Promise<string>, assistantId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/assistants/" + assistantId, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async updateTeamAssistant(getAccessTokenSilently: () => Promise<string>, assistantId: string | number, title?: string, description?: string, disabled?: boolean): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/assistants/" + assistantId, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({title, description, disabled})
        })
        return toJsonOrRaise(response)
    }

    static async getAssistantArticles(getAccessTokenSilently: () => Promise<string>, assistantId: string | number, page: number, pageSize: number): Promise<any> {
        const params = new URLSearchParams({page: page.toString(), size: pageSize.toString()})
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/assistants/" + assistantId + "/articles" + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createAssistantArticle(getAccessTokenSilently: () => Promise<string>, assistantId: string | number, title: string): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/assistants/" + assistantId + "/articles", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({title})
        })
        return toJsonOrRaise(response)
    }

    static async createAssistantArticleContentByFile(getAccessTokenSilently: () => Promise<string>, articleId: string | number, file: File): Promise<any> {
        const accessToken = await getAccessTokenSilently()

        const formData = new FormData()
        formData.append('file', file, file.name)

        const response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId + "/content/file", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: formData
        })
        return raiseForStatus(response)
    }

    static async createAssistantArticleContentByUrl(getAccessTokenSilently: () => Promise<string>, articleId: string | number, url: string): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId + "/content/url", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url})
        })
        return raiseForStatus(response)
    }

    static async deleteAssistantArticle(getAccessTokenSilently: () => Promise<string>, articleId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async getAssistantArticleContent(getAccessTokenSilently: () => Promise<string>, articleId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        let response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId + "/content", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        response = raiseForStatus(response)
        return response.text()
    }

    static async getAssistantArticle(getAccessTokenSilently: () => Promise<string>, articleId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async updateAssistantArticleContent(getAccessTokenSilently: () => Promise<string>, articleId: string | number, content: string): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId + "/content", {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'text/plain'
            },
            body: content
        })
        return raiseForStatus(response)
    }

    static async getAssistant(getAccessTokenSilently: () => Promise<string>, assistantId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/assistants/" + assistantId, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async getAdminTeams(getAccessTokenSilently: () => Promise<string>, name?: string, page: number = 1, pageSize: number = 25): Promise<Team[]> {
        const paramsObj: Record<string, string> = {page: page.toString(), size: pageSize.toString()}
        if (name) {
            paramsObj["name"] = name
        }
        const params = new URLSearchParams(paramsObj)

        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + '/api/admin/teams' + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async createAssistantArticleDeployment(getAccessTokenSilently: () => Promise<string>, articleId: string | number): Promise<any> {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/articles/" + articleId + "/deployments", {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async getAdminOpenAIUsage(getAccessTokenSilently: () => Promise<string>, date?: Date, teamId?: string | number): Promise<any> {
        const paramsValues: Record<string, string> = {}
        if (date != null) {
            const datify = (date: Date) => date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0')
            paramsValues["date"] = datify(date)
        }
        if (teamId != null) {
            paramsValues["team_id"] = teamId.toString()
        }

        const params = new URLSearchParams(paramsValues)
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/admin" + "/usage/openai" + "?" + params, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

}
