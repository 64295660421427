//
//
//  Utils
//
//

import {RequestError} from "./errors.ts";

export function raiseForStatus(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }
    return response
}


export function toJsonOrRaise(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }

    return response.json()
}

export function formatDate(date: Date): string {
    // Get the individual components (day, month, year, hours, minutes)
    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-based, so add 1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    // Add leading zeros to ensure two-digit formatting
    const formattedDay = day < 10 ? '0' + day : day
    const formattedMonth = month < 10 ? '0' + month : month
    const formattedHours = hours < 10 ? '0' + hours : hours
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

    // Create the formatted string
    return `${formattedDay}/${formattedMonth}/${year} @ ${formattedHours}:${formattedMinutes}`
}

export function generateRandomNumber() {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}

export function groupAndSum(data: Record<any, any>[], keyProp: any, valueProp: any) {
    const groupedData: Record<any, any> = {}

    data.forEach((item) => {
        const key = typeof keyProp === 'function' ? keyProp(item).toString() : item[keyProp].toString();
        const value = typeof valueProp === 'function' ? valueProp(item) as number : item[valueProp] as number;

        if (!groupedData[key]) {
          groupedData[key] = { name: key, value: 0 }
        }

        groupedData[key]["value"] += value
    })

    return Object.values(groupedData)
}

export function objectAverage(arrayOfObjects: any[], key: string) {
    if (arrayOfObjects.length === 0) {
        return 0
    }

    let sum = 0

    arrayOfObjects.forEach((obj: any) => {
          sum += obj[key]
    });

    return sum / arrayOfObjects.length
}

export function groupByDate(data: Record<any, any>[], dateKey: string) {
    const currentDate = new Date()

    // Define date ranges
    const today = new Date(currentDate)
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date(currentDate)
    yesterday.setDate(currentDate.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)

    const last7Days = new Date(currentDate)
    last7Days.setDate(currentDate.getDate() - 6)
    last7Days.setHours(0, 0, 0, 0)

    const lastMonth = new Date(currentDate)
    lastMonth.setMonth(currentDate.getMonth() - 1)
    lastMonth.setHours(0, 0, 0, 0)

    // Categorize objects based on date ranges
    const groupedData: any = {
        "today": [],
        "yesterday": [],
        'last_7_days': [],
        'last_month': [],
        "previous": [],
    };

    data.forEach((item: any) => {
        const itemDate = new Date(item[dateKey])

        if (itemDate >= today) {
            groupedData["today"].push(item)
        } else if (itemDate >= yesterday) {
            groupedData["yesterday"].push(item)
        } else if (itemDate >= last7Days) {
            groupedData["last_7_days"].push(item)
        } else if (itemDate >= lastMonth) {
            groupedData["last_month"].push(item)
        } else {
            groupedData["previous"].push(item)
        }
    })

    return groupedData
}


export function formatDateToDayMonth(date: Date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    return `${day} ${month}`;
}

export function getAllDatesInMonth(inputDate: Date) {
    const dateArray = []
    const year = inputDate.getFullYear()
    const month = inputDate.getMonth()

    const startDate = new Date(year, month, 1)
    const endDate = new Date(year, month + 1, 0)

    const currentDate = new Date(startDate)

    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
}

export function groupBy(array: { [key: string]: any }[], key: string) {
    return array.reduce((result, currentItem) => {
        const keyValue = currentItem[key]

        // Check if the key already exists in the result object
        if (!result[keyValue]) {
            // If not, create an array for that key
            result[keyValue] = []
        }

        // Push the current item to the array associated with the key
        result[keyValue].push(currentItem)

        return result
    }, {})
}
