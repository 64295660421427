//
//
//  Conversation
//
//

import {Link, useParams} from "react-router-dom";
import DashboardHeader from "../../../../components/DashboardHeader.tsx";
import {Avatar, Button, Container, Flex, LoadingOverlay, SimpleGrid, Text} from "@mantine/core";
import {IconArrowLeft, IconAssembly} from "@tabler/icons-react";
import {useEffect, useState} from "react";
import {API} from "../../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {Conversation, ConversationMessage, Assistant as AssistantInterface} from "../../../../interfaces.ts";
import {formatDate} from "../../../../utils.ts";
import {DataTable} from "mantine-datatable";
import MarkdownViewer from "../../../../components/MarkdownViewer.tsx";
import {useTranslate} from "@tolgee/react";


function DashboardConversation() {
    const {t} = useTranslate()
    const {getAccessTokenSilently} = useAuth0()
    const [assistant, setAssistant] = useState<AssistantInterface | null>(null)
    const [messages, setMessages] = useState<ConversationMessage[]>([])
    const [conversation, setConversation] = useState<Conversation>()
    const { conversationId } = useParams()

    useEffect(() => {
        if (!conversationId) {
            return
        }

        API.getConversation(getAccessTokenSilently, conversationId).then(response => {
            setConversation(response)
        })

        API.getConversationMessages(getAccessTokenSilently, conversationId).then(response => {
            setMessages(response)
        })
    }, [conversationId, getAccessTokenSilently])

    useEffect(() => {
        if (conversation == null) {
            return
        }

        if (conversation.assistant_id == null) {
            return
        }

        API.getAssistant(getAccessTokenSilently, conversation.assistant_id)
            .then(response => {
                setAssistant(response)
            }).catch(err => {
                console.error(err)
            })
    }, [conversation, getAccessTokenSilently])

    if (conversation == null) {
        return <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval"}}/>
    }

    return (
        <>
            <DashboardHeader
                title={t("dashboard.conversation.title", "Conversation")}
                leftSection={
                    <Button
                        component={Link}
                        to="/dashboard/conversations"
                        variant="subtle"
                        leftSection={<IconArrowLeft size={16}/>}
                    >
                        {t("dashboard.conversation.conversations", "Conversations")}
                    </Button>
                }
            />
            <Container mt={20}>
                <Flex direction="column" mt={20} mb="md" gap={4}>
                    <Text size="sm" fw={600}>{t("dashboard.conversation.description", "Description")}</Text>
                    <Text size="sm">{conversation.name}</Text>
                </Flex>
                <SimpleGrid cols={3}>
                    <Flex direction="column" gap={4}>
                        <Text size="sm" fw={600}>{t("dashboard.conversation.num-messages", "Number of Messages")}</Text>
                        <Text size="sm">{conversation.messages}</Text>
                    </Flex>
                    {conversation.user != null && <Flex direction="column" gap={4}>
                        <Text size="sm" fw={600}>{t("dashboard.conversation.created-by", "Created By")}</Text>
                        <Flex gap={10} align="center">
                            <Avatar variant="white" size="xs" radius="xl" src={conversation.user?.picture}/>
                            <span>{conversation.user.name}</span>
                        </Flex>
                    </Flex>}
                    <Flex direction="column" gap={4}>
                        <Text size="sm" fw={600}>{t("dashboard.conversation.created-at", "Created At")}</Text>
                        <Text size="sm">{formatDate(new Date(conversation.created_at))}</Text>
                    </Flex>
                    {assistant != null &&
                        <Flex direction="column" gap={4}>
                            <Text size="sm" fw={600}>{t("dashboard.conversation.assistant", "Assistant")}</Text>
                            <Text size="sm">{assistant.title}</Text>
                        </Flex>
                    }
                </SimpleGrid>
                {conversation.summary &&
                    <>
                        <Text size="sm" fw={600} mt={30} mb={4}>{t("dashboard.conversation.summary", "Summary")}</Text>
                        <Text size="sm">{conversation.summary}</Text>
                    </>
                }

                <Text size="sm" fw={600} mt={30} mb={4}>{t("dashboard.conversation.messages", "Messages")}</Text>
                <DataTable
                    withRowBorders={false}
                    records={messages}
                    noHeader={true}
                    verticalSpacing="md"
                    columns={[
                        {
                            accessor: "message",
                            render: ({id, message, is_user}) => {
                                let avatar = undefined
                                let name = undefined

                                if (is_user) {
                                    avatar = (
                                        <Avatar size="sm" radius="xl" src={conversation.user?.picture}/>
                                    )
                                    name = conversation.user?.name || "User"
                                } else {
                                    avatar = (
                                        <Avatar size="sm" radius="xl">
                                            <IconAssembly size={16}/>
                                        </Avatar>
                                    )
                                    name = assistant?.title || "Assistant"
                                }

                                return (
                                    <Flex key={id} gap={14}>
                                        {avatar}
                                        <Flex direction="column">
                                            <Text fw={600} size="sm">{name}</Text>
                                            <MarkdownViewer markdown={message}/>
                                        </Flex>
                                    </Flex>
                                )
                            }
                        }
                    ]}
                    minHeight={messages.length > 0 ? 0 : 200}
                />
            </Container>
        </>
    )
}

export default DashboardConversation
