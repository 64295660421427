//
//
//  Knowledge Base
//
//

import {Button, Flex, Text, Title} from "@mantine/core";
import {useEffect, useState} from "react";
import classes from "./index.module.css";
import DashboardHeader from "../../../components/DashboardHeader.tsx";
import {IconMoodSad, IconVocabulary} from "@tabler/icons-react";
import Assistant from "./Assistant.tsx";
import {Assistant as AssistantInterface} from "../../../interfaces.ts";
import {DataTable} from "mantine-datatable";
import {modals} from "@mantine/modals";
import NewAssistantModal from "./NewAssistantModal.tsx";
import {API} from "../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {useTeam} from "../../../contexts/TeamContext.tsx";
import {useTranslate} from "@tolgee/react";

function DashboardKnowledgeBase() {
    const {t} = useTranslate()
    const {team} = useTeam()
    const {getAccessTokenSilently} = useAuth0()
    const [assistantsLoading, setAssistantsLoading] = useState<boolean>(false)
    const [selectedAssistant, setSelectedAssistant] = useState<any>(null)
    const [assistants, setAssistants] = useState<any[]>([])

    useEffect(() => {
        setAssistantsLoading(true)
        API.getTeamAssistants(getAccessTokenSilently, team.id)
            .then((response: AssistantInterface[]) => {
                setAssistants(response)

                let assistantToSelect = null
                const cacheSelectedAssistantIdText = sessionStorage.getItem("dashboard-knowledge-base-selected-assistant")
                if (cacheSelectedAssistantIdText != null) {
                    const cacheSelectedAssistantId = parseInt(cacheSelectedAssistantIdText)
                    assistantToSelect = response.find(assistant => assistant.id == cacheSelectedAssistantId)
                }

                if (assistantToSelect == null && selectedAssistant == null && response.length > 0) {
                    assistantToSelect = response[0]
                }

                setSelectedAssistant(assistantToSelect)
            })
            .catch((error) => {
                console.error(error)
            }).finally(() => {
                setAssistantsLoading(false)
            })
    }, [getAccessTokenSilently, team.id])


    function onSelectAssistant(newAssistant: AssistantInterface) {
        setSelectedAssistant(newAssistant)
        sessionStorage.setItem("dashboard-knowledge-base-selected-assistant", newAssistant.id.toString())
    }

    const createAssistantModal = () => {
        modals.open({
            title: t("dashboard.assistant.create", "Create Assistant"),
            children: (
                <NewAssistantModal
                    onSubmit={(values) => {
                        setAssistantsLoading(true)
                        API.createTeamAssistant(getAccessTokenSilently, team.id, values.title, values.description)
                            .then((response) => {
                                setAssistants([...assistants, response])
                                setSelectedAssistant(response)
                            })
                            .catch((error) => {
                                console.error(error)
                            }).finally(() => {
                                modals.closeAll()
                                setAssistantsLoading(false)
                            })
                    }}
                    onCancel={() => {
                        modals.closeAll()
                    }}
                />
            )
        })
    }

    const header = (
        <DashboardHeader
            rightSection={
                <Button color="grape" onClick={createAssistantModal}>{t("dashboard.knowledge-base.create", "Create Assistant")}</Button>
            }
            title={t("dashboard.knowledge-base.title", "Knowledge Base")}
        />
    )

    return (
        <>
            {header}
            <Flex>
                <nav className={classes.nav}>
                    <DataTable
                        noHeader
                        records={assistants}
                        withRowBorders={false}
                        columns={[
                            {
                                accessor: "title",
                                title: t("dashboard.assistant.title", "Title"),
                                ellipsis: true,
                                width: 300,
                                render: (assistant) => {
                                    return (
                                        <Button
                                            size="xs"
                                            onClick={() => onSelectAssistant(assistant)}
                                            variant={assistant.id == selectedAssistant?.id ? "filled" : "subtle"}
                                            key={assistant.id}
                                            style={{width: "100%"}}
                                            classNames={{inner: classes.navButton}}
                                        >
                                            <Flex gap={10} align="center" className={classes.assistantNameContainer}>
                                                <IconVocabulary size={16}/>
                                                <Text size="sm" td={assistant.disabled ? "line-through" : undefined} className={classes.assistantName}>{assistant.title}</Text>
                                            </Flex>
                                        </Button>
                                    )
                                }
                            }
                        ]}
                        fetching={assistantsLoading}
                        className={classes.navTable}
                        minHeight={assistants.length > 0 ? 0 : 300}
                        emptyState={
                            <>
                                <Flex justify="center" align="center" direction="column" gap={20}>
                                    <IconMoodSad size={60}/>
                                    <Title order={4}>{t("dashboard.knowledge-base.no-assistants", "No Assistants found")}</Title>
                                    <Button onClick={createAssistantModal}>{t("dashboard.knowledge-base.create-assistant", "Create Assistant")}</Button>
                                </Flex>
                            </>
                        }
                    />
                </nav>
                <Flex ml={40} style={{width: "100%"}}>
                    {selectedAssistant &&
                        <Assistant
                            assistant={selectedAssistant}
                            onUpdate={(newASsistant: AssistantInterface) => {
                                setAssistants(assistants.map(assistant => assistant.id == newASsistant.id ? newASsistant : assistant))
                                if (selectedAssistant.id == newASsistant.id) {
                                    setSelectedAssistant(newASsistant)
                                }
                            }}
                            onDelete={(deletedAssistant: AssistantInterface) => {
                                setAssistants(assistants.filter(assistant => assistant.id != deletedAssistant.id))
                                setSelectedAssistant(null)
                            }}
                        />
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default DashboardKnowledgeBase
