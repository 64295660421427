//
//
//  New Article Start
//
//

import {Flex, SimpleGrid, Title, Text} from "@mantine/core";
import {IconFiles, IconPlus, IconWorldWww} from "@tabler/icons-react";
import classes from "./NewArticleStart.module.css"
import {useTranslate} from "@tolgee/react";

function NewArticleStart({onSubmit}: {onSubmit: (type: string) => void}) {
    const {t} = useTranslate()

    return (
        <Flex direction="column" align="center" mb={12} mr={12} ml={12}>
            <Title mb={20} order={5}>How would you like to start?</Title>
            <SimpleGrid cols={3} spacing="xl">
                <Flex direction="column" align="center">
                    <button className={classes.templateButtonScratch} onClick={() => onSubmit("blank")}>
                        <IconPlus size={42}/>
                    </button>
                    <Title mt={8} order={6}>{t("dashboard.new-article.blank", "Blank Article")}</Title>
                    <Text ta="center" size="sm" c="dimmed">{t("dashboard.new-article.start-blank", "Start from scratch")}</Text>
                </Flex>
                <Flex direction="column" align="center">
                    <button className={classes.templateButton} onClick={() => onSubmit("files")}>
                        <IconFiles size={42} color="#51CF66"/>
                    </button>
                    <Title mt={8} order={6}>{t("dashboard.new-article.file", "Use file")}</Title>
                    <Text ta="center" size="sm" c="dimmed">{t("dashboard.new-article.start-file", "Start from file")}</Text>
                </Flex>
                <Flex direction="column" align="center">
                    <button className={classes.templateButton} onClick={() => onSubmit("urls")}>
                        <IconWorldWww color="#339AF0" size={42}/>
                    </button>
                    <Title mt={8} order={6}>{t("dashboard.new-article.url", "Use URL")}</Title>
                    <Text ta="center" size="sm" c="dimmed">{t("dashboard.new-article.start-url", "Start from URL")}</Text>
                </Flex>
            </SimpleGrid>
        </Flex>
    )
}

export default NewArticleStart
