//
//
//  Header
//
//

import classes from "./DashboardHeader.module.css";
import {Title} from "@mantine/core";
import {ReactNode} from "react";

function DashboardHeader({title, leftSection, rightSection}: {title?: string, leftSection?: ReactNode, rightSection?: ReactNode }) {
    return (
        <div className={classes.header}>
            {leftSection || <div/>}
            {title == null ? <div/> : <Title order={4}>{title}</Title>}
            {rightSection || <div/>}
        </div>
    )
}

export default DashboardHeader
