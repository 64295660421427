//
//
//  Metric
//
//

import {Flex, Title, Text} from "@mantine/core"
import {ReactNode} from "react"
import DashboardCard from "./DashboardCard.tsx"
import classes from "./MetricCard.module.css"

function MetricCard({title, value, icon}: {title: string, value: number | string, icon: ReactNode}) {
    return (
        <DashboardCard>
            <Flex justify="space-between" align="center">
                <Text c="dimmed" fw={600} size="sm">{title}</Text>
                <div className={classes.iconWrapper}>
                    {icon}
                </div>
            </Flex>
            <Title order={2} style={{"textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": "nowrap"}}>{value}</Title>
        </DashboardCard>
    )
}

export default MetricCard
