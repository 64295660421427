//
//
//  Members
//
//

import {Avatar, Container, Title} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {useEffect, useState} from "react";
import {API} from "../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {useTeam} from "../../../contexts/TeamContext.tsx";
import {useTranslate} from "@tolgee/react";

const PAGE_SIZE = 25

function DashboardMembers() {
    const {t} = useTranslate()
    const [loading, setLoading] = useState(false)
    const {getAccessTokenSilently} = useAuth0()
    const {team} = useTeam()
    const [totalMembers, setTotalMembers] = useState(0)
    const [page, setPage] = useState(1)
    const [members, setMembers] = useState<any[]>([])

    useEffect(() => {
        setLoading(true)
        API.getTeamMembers(getAccessTokenSilently, team.id, page, PAGE_SIZE).then(response => {
            setMembers(response["items"])
            setTotalMembers(response["total"])
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            setLoading(false)
        })
    }, [getAccessTokenSilently, page, team.id])

    return (
        <Container mt={20}>
            <Title order={1} mb={50}>{t("dashboard.members.title", "Members")}</Title>
            <DataTable
                records={members}
                columns={[
                    {
                        accessor: "avatar",
                        title: "",
                        width: 75,
                        render: ({user}) => {
                            return <Avatar src={user.picture}/>
                        }
                    },
                    {
                        accessor: "name",
                        ellipsis: true,
                        title: t("dashboard.members.name", "Name"),
                        render: ({user}) => {
                            return user.name
                        }
                    },
                    {
                        accessor: "email",
                        title: t("dashboard.members.email", "Email"),
                        render: ({user}) => {
                            return user.email
                        }
                    },
                    {
                        accessor: "role",
                        title: t("dashboard.members.role", "Role"),
                        render: ({role}) => {
                            if (role === "EDITOR") {
                                return t("dashboard.members.editor", "Editor")
                            } else {
                                return t("dashboards.members.viewer", "Viewer")
                            }
                        }
                    },
                ]}
                fetching={loading}
                verticalSpacing="sm"
                minHeight={members.length > 0 ? 0 : 200}
                totalRecords={totalMembers}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
            />
        </Container>
    )
}

export default DashboardMembers
