//
//
//  Article
//
//

import {Link, useParams} from "react-router-dom";
import DashboardHeader from "../../../../components/DashboardHeader.tsx";
import {Button, Container, Flex, Title, Text} from "@mantine/core";
import {useEffect, useState} from "react";
import {IconArrowLeft} from "@tabler/icons-react";
import {useEditor} from '@tiptap/react'
import {StarterKit} from "@tiptap/starter-kit";
import {RichTextEditor} from "@mantine/tiptap";
import {Placeholder} from "@tiptap/extension-placeholder";
import {API} from "../../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {notifications} from "@mantine/notifications";
import BongoCat from "../../../../components/BongoCat.tsx";
import classes from "./index.module.css"
import {AssistantArticle as AssistantArticleInterface} from "../../../../interfaces.ts";
import BongoCatDepressed from "../../../../components/BongoCatDepressed.tsx";
import useAdmin from "../../../../hooks/admin.ts";
import {useTranslate} from "@tolgee/react";


function DashboardKnowledgeBaseArticle() {
    const {t} = useTranslate()
    const isAdmin = useAdmin()
    const [isDeployed, setIsDeployed] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [deployLoading, setDeployLoading] = useState(false)
    const [modified, setModified] = useState(false)
    const {getAccessTokenSilently} = useAuth0()
    const [article, setArticle] = useState<AssistantArticleInterface | null>(null)
    const params = useParams()

    const editor = useEditor({
        extensions: [
            StarterKit,
            Placeholder.configure({
                placeholder: t("dashboard.article.start-writing", 'Start writing your article ...')
            }),
        ],
        content: "",
        autofocus: true,
    })

    useEffect(() => {
        function saveContent(e: KeyboardEvent) {
            if (e.key === "s" && (/macintosh/i.test(navigator.userAgent) ? e.metaKey : e.ctrlKey)) {
                e.preventDefault()
                onSave()
                    .catch(error => {
                        console.error(error)
                    })
            }
        }
        window.addEventListener("keydown", saveContent)

        return () => {
            window.removeEventListener("keydown", saveContent)
        }
    }, [editor])

    useEffect(() => {
        if (!params.articleId) {
            return
        }

        const articlePromise = API.getAssistantArticle(getAccessTokenSilently, params.articleId!)
        const contentPromise = API.getAssistantArticleContent(getAccessTokenSilently, params.articleId!)

        Promise.all([articlePromise, contentPromise])
            .then(([responseArticle, responseContent]) => {
                setArticle(responseArticle)
                setIsDeployed(responseArticle.is_draft_deployed)
                editor?.commands?.setContent(responseContent.replaceAll("\n", "<br>"))
            }).catch(error => {
                console.error(error)
            }).finally(() => {

            })
    }, [params.articleId, editor, getAccessTokenSilently])

    useEffect(() => {
        if (!params.articleId) {
            return
        }

        if (article != null && !article.is_processing) {
            return
        }

        const interval = setInterval(async () => {
            const articleUpdated = await API.getAssistantArticle(getAccessTokenSilently, params.articleId!)

            if (!articleUpdated.is_processing) {
                const updatedContent = await API.getAssistantArticleContent(getAccessTokenSilently, params.articleId!)
                editor?.commands?.setContent(updatedContent.replaceAll("\n", "<br>"))
                clearInterval(interval)
            }

            setArticle(articleUpdated)
        }, 15000)
        return () => {
            clearInterval(interval)
        }
    }, [editor, getAccessTokenSilently, params.articleId])

    useEffect(() => {
        if (editor == null) {
            return
        }

        editor.on("update", () => {
            setModified(true)
        })
    }, [editor])

    async function onSave() {
        if (params.articleId == null) {
            return Promise.resolve()
        }

        if (!modified) {
            return Promise.resolve()
        }

        setIsDeployed(false)
        setSaveLoading(true)
        setModified(false)

        const content = editor?.getText() || ""
        try {
            await API.updateAssistantArticleContent(getAccessTokenSilently, params.articleId!, content);
            notifications.show({
                title: t("dashboard.article.saved", "Saved!"),
                message: t("dashboard.article.saved-successfully", "Article saved successfully!"),
                color: "teal",
            });
        } finally {
            setSaveLoading(false);
        }
    }

    async function onDeploy() {
        if (params.articleId == null) {
            return
        }

        setDeployLoading(true)

        onSave()
            .then(() => {
                return API.createAssistantArticleDeployment(getAccessTokenSilently, params.articleId!)
            }).then(() => {
                setIsDeployed(true)
                notifications.show({
                    title: t("dashboard.article.deployed", "Deployed!"),
                    message: t("dashboard.article.deployed-message", "Article deployed successfully! It may take a while to reflect changes in the assistant."),
                    color: "teal",
                })
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setDeployLoading(false)
            })
    }

    const BackToAssistantsButton = (
        <Button
            component={Link}
            to="/dashboard/knowledge-base"
            variant="subtle"
            leftSection={<IconArrowLeft size={16}/>}
        >
            {t("dashboard.article.knowledge-base", "Knowledge Base")}
        </Button>
    )

    if (article == null)  {
        return (
            <>
                <DashboardHeader
                    title=""
                    leftSection={BackToAssistantsButton}
                />
            </>
        )
    }

    if (article.is_processing) {
        return (
            <>
                <DashboardHeader
                    title={article.title}
                    leftSection={BackToAssistantsButton}
                />
                <Flex align="center" direction="column" style={{width: "100%"}} mt={60}>
                    <Title order={1} mb={20}>{t("dashboard.article.processing", "Processing your article 🚀")}</Title>
                    <Text size="lg" fw={500}>{t("dashboard.article.grab-coffee", "Grab a coffee because it may take a while ...")}</Text>
                    <BongoCat/>
                </Flex>
            </>
        )
    }

    if (article.has_error) {
        return (
            <>
                <DashboardHeader
                    title={article.title}
                    leftSection={BackToAssistantsButton}
                />
                <Flex align="center" direction="column" style={{width: "100%"}} mt={60}>
                    <Title order={1} mb={20}>{t("dashboard.article.error", "An error occurred")}</Title>
                    <Text size="lg" fw={500}>{t("dashboard.article.error-message", "We couldn't process your article")}</Text>
                    <BongoCatDepressed/>
                </Flex>
            </>
        )
    }

    return (
        <div className={classes.content}>
            <div className={classes.header}>
                <DashboardHeader
                    title={article.title}
                    leftSection={BackToAssistantsButton}
                    rightSection={
                        <Flex gap={12}>
                            <Button onClick={onSave} disabled={!modified && !isAdmin} variant="outline" loading={saveLoading}>{t("dashboard.article.save-draft", "Save Draft")}</Button>
                            <Button onClick={onDeploy} disabled={!modified && isDeployed && !isAdmin} loading={deployLoading}>{t("dashboard.article.deploy", "Deploy")}</Button>
                        </Flex>
                    }
                />
            </div>
            <div style={{backgroundColor: "rgb(247, 247, 247)", minHeight: "calc(100vh - 68px)", paddingTop: "5rem"}}>
                <Container>
                    <RichTextEditor editor={editor}>
                        <RichTextEditor.Content />
                    </RichTextEditor>
                </Container>
            </div>
        </div>
    )
}

export default DashboardKnowledgeBaseArticle
