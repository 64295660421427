//
//
//  User Home
//
//

import classes from "./index.module.css"
import maiaLogoImg from '../../assets/maia-logo.svg'
import {
    ActionIcon,
    AppShell,
    Avatar,
    Badge,
    Container,
    Divider,
    Flex,
    Loader,
    Text,
    TextInput, Tooltip,
    useMantineColorScheme
} from "@mantine/core"
import {Assistant as AssistantInterface} from "../../interfaces"
import {IconAssembly, IconMessage2Plus, IconSend2} from "@tabler/icons-react"
import {useForm} from "@mantine/form"
import {useAuth0} from "@auth0/auth0-react"
import {useEffect, useRef, useState} from "react"
import {useTeam} from "../../contexts/TeamContext.tsx"
import {API} from "../../api.ts";
import {Conversation, DraftMessage} from "../../interfaces.ts";
import {formatDate, generateRandomNumber, groupByDate} from "../../utils.ts";
import ProfileMenu from "../../components/ProfileMenu.tsx";
import MarkdownViewer from "../../components/MarkdownViewer.tsx";
import AssistantsSelector from "./AssistantsSelector.tsx";
import {useTranslate} from "@tolgee/react";

function Chat() {
    const {t} = useTranslate()
    const {team} = useTeam()
    const messagesEndRef = useRef<HTMLDivElement>(null)
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [messageIdLoading, setMessageIdLoading] = useState<number | null>(null)
    const [currentAssistant, setCurrentAssistant] = useState<AssistantInterface | null>(null)
    const [conversations, setConversations] = useState<Conversation[]>([])
    const [conversationId, setConversationId] = useState<number | undefined>(undefined)
    const {setColorScheme} = useMantineColorScheme()
    const {getAccessTokenSilently, user} = useAuth0()
    const messagesRef = useRef<DraftMessage[]>([])
    const [messagesState, setMessagesState] = useState<DraftMessage[]>([])
    const form = useForm({
        initialValues: {
            message: '',
        }
    })

    function setMessages(newMessages: DraftMessage[]) {
        messagesRef.current = newMessages
        setMessagesState(newMessages)
    }

    useEffect(() => {
        API.getConversations(getAccessTokenSilently, team.id)  // Get only first page
            .then(responseConversations => {
                setCurrentAssistant(null)
                setConversations(responseConversations["items"])
                setConversationId(undefined)
                setMessages([])
            })
    }, [getAccessTokenSilently, team.id])

    useEffect(() => {
        setColorScheme("dark")
    }, [])

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messagesState])

    async function onSubmitMessage(values: any) {
        form.reset()

        if (currentAssistant == null) {
            console.error("No assistant selected")
            return
        }

        let messageConversationId = conversationId

        if (messageConversationId == null) {
            setMessages([])
        }

        const userMessage = {id: generateRandomNumber(), message: values.message, is_user: true}
        setMessages([...messagesRef.current, userMessage])

        if (messageConversationId == null) {
            const response = await API.createConversation(getAccessTokenSilently, team.id, currentAssistant.id)
            messageConversationId = response.id
            setConversationId(messageConversationId)
            setConversations([response, ...conversations])
        }

        const botMessage = {id: generateRandomNumber(), message: "", is_user: false}
        const botMessageIndex = messagesRef.current.push(botMessage) - 1
        setMessagesState([...messagesRef.current])

        setMessageIdLoading(botMessage["id"])

        const response = await API.createConversationMessage(getAccessTokenSilently, messageConversationId!, values.message)
        const reader = response.body!.pipeThrough(new TextDecoderStream()).getReader()

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const {value, done} = await reader.read()
            setMessageIdLoading(null)

            if (done) {
                break
            }
            messagesRef.current[botMessageIndex].message += value
            setMessagesState([...messagesRef.current])
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }

    function onNewConversation() {
        setConversationId(undefined)
        setCurrentAssistant(null)
        setMessages([])
    }

    function onChangeConversation(conversationId: number) {
        const conversation = conversations.find((conversation: Conversation) => conversation.id == conversationId)

        if (conversation == null) {
            return
        }

        setLoadingMessages(true)
        setConversationId(conversationId)
        setCurrentAssistant(null)

        const getAssistantPromise = API.getAssistant(getAccessTokenSilently, conversation.assistant_id)
        const getConversationMessagesPromise = API.getConversationMessages(getAccessTokenSilently, conversationId)

        Promise.all([getAssistantPromise, getConversationMessagesPromise])
            .then(([responseAssistant, responseMessages]) => {
                setCurrentAssistant(responseAssistant)
                setMessages(responseMessages)
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
            }).finally(() => {
                setLoadingMessages(false)
            })
    }

    let welcomeNode = undefined
    if (loadingMessages) {
        welcomeNode = (
            <Loader style={{height: 200}} size="xl" variant="oval"/>
        )
    } else if (conversationId == null) {
        welcomeNode = (
            <AssistantsSelector
                onSelect={(assistant: AssistantInterface) => {
                    setCurrentAssistant(assistant)
                }}
                onDeselect={() => setCurrentAssistant(null)}
            />
        )
    }

    const groupedConversations = groupByDate(conversations, "created_at")
    const textByGroup: Record<string, string> = {
        today: t("chat.today", "Today"),
        yesterday: t("chat.yesterday", "Yesterday"),
        last_7_days: t("chat.previous-7-days", "Previous 7 days"),
        last_month: t("chat.previous-30-days", "Previous 30 days"),
        previous: t("chat.previous", "Previous")
    }

    return (
        <>
            <AppShell
                navbar={{width: 260, breakpoint: 'sm', collapsed: {mobile: true}}}
                padding="md"
                className={classes.root}
            >
                <AppShell.Navbar p="md" className={classes.navbar}>
                    <button className={classes.navbarBtn} style={{justifyContent: "space-between"}}
                            onClick={onNewConversation}>
                        <div className={classes.newChat}>
                            <div className={classes.logoWrapper}>
                                <Avatar size="sm" radius="xl" src={maiaLogoImg}/>
                            </div>
                            <span>{t("chat.new", "New chat")}</span>
                        </div>
                        <IconMessage2Plus size={20}/>
                    </button>
                    <div className={classes.navbarContent}>
                        <Flex direction="column" gap={12}>
                            {Object.entries(groupedConversations).flatMap(([group, conversations]) => {
                                if ((conversations as Conversation[]).length === 0) {
                                    return undefined
                                }

                                return (
                                    <Flex direction="column" key={group} gap={6}>
                                        <Text size="xs" c="gray" mb={5} mt={10}>{textByGroup[group]}</Text>
                                        {(conversations as Conversation[]).map((conversation: Conversation) => {
                                            const conversationName = conversation.name == null ? formatDate(new Date(conversation.created_at)) : conversation.name
                                            return (
                                                <Tooltip label={conversationName} key={conversation.id}>
                                                    <button className={classes.navbarBtn}
                                                            data-active={conversation.id == conversationId}
                                                            onClick={() => onChangeConversation(conversation.id)}>
                                                        <Text size="sm" fs={conversation.name == null ? "italic" : undefined}
                                                              className={classes.navbarConversationTitle}>
                                                            {conversationName}
                                                        </Text>
                                                    </button>
                                                </Tooltip>
                                            )
                                        })}
                                    </Flex>
                                )
                            })}
                        </Flex>
                    </div>
                    <Flex direction="column">
                        <Divider mb={10}/>
                        <ProfileMenu
                            includeDashboard={team.role == "EDITOR"}
                            includeAdmin={true}
                        />
                    </Flex>
                </AppShell.Navbar>
                <AppShell.Main className={classes.main}>
                    <Container className={classes.mainContainer}>
                        <div className={classes.welcomeWrapper}>
                            {welcomeNode}
                        </div>
                        <Flex className={classes.chat} gap={40} direction="column" mt={15} mb={30}>
                            {messagesState.map((message) => {
                                let avatar = undefined
                                let name = undefined

                                if (message.is_user) {
                                    avatar = (
                                        <Avatar size="sm" radius="xl" src={user?.picture}/>
                                    )
                                    name = "You"
                                } else {
                                    avatar = (
                                        <Avatar size="sm" radius="xl">
                                            <IconAssembly size={16}/>
                                        </Avatar>
                                    )
                                    name = currentAssistant?.title
                                }

                                return (
                                    <Flex key={message.id} gap={14}>
                                        {avatar}
                                        <Flex direction="column" gap={5}>
                                            <Flex align="center" gap={6}>
                                                <Text fw={700}>{name}</Text>
                                                {messageIdLoading == message.id &&
                                                    <Badge size="sm" color="rgb(250, 240, 230)">
                                                        <Flex align="center" gap={10}>
                                                            <Loader color="grape" size={14} type="dots"/>
                                                            <Text size="xs" c="grape" tt="lowercase">{t("chat.thinking", "Thinking")}</Text>
                                                        </Flex>
                                                    </Badge>
                                                }
                                            </Flex>
                                            <MarkdownViewer markdown={message.message}/>
                                        </Flex>
                                    </Flex>
                                )
                            })}
                            <div ref={messagesEndRef}/>
                        </Flex>
                        {currentAssistant != null && <div className={classes.chatInputWrapper}>
                            <form onSubmit={form.onSubmit(onSubmitMessage)}>
                                <TextInput
                                    variant="unstyled"
                                    placeholder={t("chat.message-maia", "Message MAIA ...")}
                                    classNames={{
                                        input: classes.chatInput
                                    }}
                                    rightSection={
                                        <ActionIcon variant="filled" color="gray">
                                            <IconSend2 size={16} color="white"/>
                                        </ActionIcon>
                                    }
                                    {...form.getInputProps("message")}
                                />
                            </form>
                        </div>}
                    </Container>
                </AppShell.Main>
            </AppShell>
        </>
    )
}

export default Chat
