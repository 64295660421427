//
//
//  Conversations
//
//

import {Button, Container, Title} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {useEffect, useState} from "react";
import {Conversation} from "../../../interfaces.ts";
import TimeAgo from "timeago-react";
import {Link} from "react-router-dom";
import {API} from "../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {useTeam} from "../../../contexts/TeamContext.tsx";
import {useTranslate} from "@tolgee/react";

const PAGE_SIZE = 25

function DashboardConversations() {
    const {t} = useTranslate()
    const {team} = useTeam()
    const [loading, setLoading] = useState(false)
    const [totalConversations, setTotalConversations] = useState(0)
    const [page, setPage] = useState(1)
    const {getAccessTokenSilently} = useAuth0()
    const [conversations, setConversations] = useState<Conversation[]>([])

    useEffect(() => {
        setLoading(true)
        API.getConversations(getAccessTokenSilently, team.id, "TEAM", page, PAGE_SIZE).then(response => {
            setTotalConversations(response["total"])
            setConversations(response["items"])
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            setLoading(false)
        })
    }, [team.id, page, getAccessTokenSilently])

    return  (
        <>
            <Container mt={20}>
                <Title order={1} mb={50}>{t("dashboard.conversations.title", "Conversations")}</Title>
                <DataTable
                    records={conversations}
                    columns={[
                        {
                            accessor: 'name',
                            ellipsis: true,
                            title: t("dashboard.conversations.description", "Description")
                        },
                        {
                            accessor: "messages",
                            title: t("dashboard.conversations.messages", "Messages"),
                        },
                        {
                            accessor: "created_by",
                            title: t("dashboard.conversations.created_by", "Created By"),
                        },
                        {
                            accessor: "created_at",
                            title: t("dashboard.conversations.created_at", "Created At"),
                            render: ({created_at}) => {
                                return <TimeAgo datetime={created_at}/>
                            }
                        },
                        {
                            accessor: "actions",
                            title: "",
                            textAlign: "right",
                            render: ({id}) => {
                                return (
                                    <Button component={Link} to={"/dashboard/conversations/" + id} size="xs" variant="outline">{t("dashboard.conversations.details", "Details")}</Button>
                                )
                            }
                        }
                    ]}
                    verticalSpacing="sm"
                    fetching={loading}
                    minHeight={conversations.length > 0 ? 0 : 200}
                    totalRecords={totalConversations}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                />
            </Container>
        </>
    )
}

export default DashboardConversations
