//
//
//  Assistant
//
//


import {ActionIcon, Badge, Button, Flex, Title, Text, Menu, Loader} from "@mantine/core";
import {IconDots, IconEdit, IconExclamationCircle, IconPlus, IconTrash} from "@tabler/icons-react";
import {DataTable} from "mantine-datatable";
import {useEffect, useState} from "react";
import {modals} from "@mantine/modals";
import NewArticleModal from "./NewArticleModal.tsx";
import EditAssistantModal from "./EditAssistantModal.tsx";
import {Link, useNavigate} from "react-router-dom";
import {Assistant as AssistantInterface} from "../../../interfaces.ts";
import {API} from "../../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import {formatDate} from "../../../utils.ts";
import {useTranslate} from "@tolgee/react";

const PAGE_SIZE = 25

function Assistant({assistant, onUpdate, onDelete}: {assistant: any, onUpdate: (assistant: AssistantInterface) => void, onDelete: (assistant: AssistantInterface) => void}) {
    const {t} = useTranslate()
    const navigate = useNavigate()
    const {getAccessTokenSilently} = useAuth0()
    const [totalArticles, setTotalArticles] = useState(0)
    const [articlesPage, setArticlesPage] = useState(1)
    const [articlesLoading, setArticlesLoading] = useState<boolean>(false)
    const [articles, setArticles] = useState<any[]>([])

    useEffect(() => {
        setArticlesLoading(true)
        API.getAssistantArticles(getAccessTokenSilently, assistant.id, articlesPage, PAGE_SIZE)
            .then((response) => {
                setArticles(response["items"])
                setTotalArticles(response["total"])
            }).catch(error => {
                console.error(error)
            }).finally(() => {
                setArticlesLoading(false)
            })
    }, [assistant.id, articlesPage, getAccessTokenSilently])

    const editAssistantModal = () => {
        modals.open({
            title: t("dashboard.assistant.edit", "Edit Assistant"),
            children: (
                <EditAssistantModal
                    assistant={assistant}
                    onSubmit={(values) => {
                        API.updateTeamAssistant(getAccessTokenSilently, assistant.id, values.title, values.description, values.disabled)
                            .then((response: AssistantInterface) => {
                                onUpdate(response)
                            }).catch(error => {
                                console.error(error)
                            }).finally(() => {
                                modals.closeAll()
                            })
                        }
                    }
                    onCancel={() => {
                        modals.closeAll()
                    }}
                />
            )
        })
    }

    const deleteAssistantModal = () => {
        modals.openConfirmModal({
            title: t("dashboard.assistant.delete", "Delete Assistant"),
            centered: true,
            children: (
                <Text size="sm">
                    {t("dashboard.assistant.delete-confirmation", "Are you sure you want to delete the assistant? This action is destructive and you will not be able to recover the assistant once it's deleted.")}
                </Text>
            ),
            labels: { confirm: t("dashboard.assistant.delete", 'Delete Assistant'), cancel: t("dashboard.assistant.delete-cancel", "No don't delete it")},
            confirmProps: { color: 'red' },
            onCancel: () => {
                modals.closeAll()
            },
            onConfirm: () => {
                API.deleteTeamAssistant(getAccessTokenSilently, assistant.id)
                    .then(() => {
                        onDelete(assistant)
                    }).catch(error => {
                        console.error(error)
                    }).finally(() => {
                        modals.closeAll()
                    })
            },
        })
    }

    const addArticleModal = () => {
        modals.open({
            title: t("dashboard.assistant.add-article", "Add Article"),
            children: (
                <NewArticleModal
                    onSubmit={(values) => {
                        async function createAssistant() {
                            const article = await API.createAssistantArticle(getAccessTokenSilently, assistant.id, values.title)

                            if ("file" in values) {
                                await API.createAssistantArticleContentByFile(getAccessTokenSilently, article.id, values.file)
                            } else if ("url" in values) {
                                await API.createAssistantArticleContentByUrl(getAccessTokenSilently, article.id, values.url)
                            }

                            return article
                        }

                        createAssistant()
                            .then(article => {
                                navigate("/dashboard/knowledge-base/articles/" + article.id)
                            }).catch(error => {
                                console.error(error)
                            }).finally(() => {
                                modals.closeAll()
                            })
                    }}
                />
            ),
        })
    }

    const openDeleteArticleModal = (article: any) => {
        modals.openConfirmModal({
            title: t("dashboard.assistant.delete-article", "Delete Article"),
            centered: true,
            children: (
                <Text size="sm">
                    {t("dashboard.assistant.delete-article-confirmation", "Are you sure you want to delete the article? This action is destructive and you will not be able to recover the article once it's deleted.")}
                </Text>
            ),
            labels: { confirm: t("dashboard.assistant.delete-article", "Delete Article"), cancel: t("dashboard.assistant.delete-article-cancel", "No don't delete it") },
            confirmProps: { color: 'red' },
            onConfirm: () => {
                API.deleteAssistantArticle(getAccessTokenSilently, article.id)
                    .then(() => {
                        setArticles(articles.filter(a => a.id !== article.id))
                    }).catch(error => {
                        console.error(error)
                    }).finally(() => {

                    })
            },
        })
    }

    return (
        <Flex direction="column" style={{width: "100%"}} gap={20}>
            <Flex direction="row" justify="space-between" style={{width: "100%"}}>
                <Flex direction="column" gap={10}>
                    <Title order={5} mt={20}>{assistant.title}</Title>
                    <Badge color={assistant.disabled ? "red" : "lime"}>{assistant.disabled ? t("dashboard.assistant.disabled", "Disabled") : t("dashboard.assistant.enabled", "Enabled")}</Badge>
                    <Text>{assistant.description}</Text>
                </Flex>
                <Flex align="center" gap={20}>
                    <Button leftSection={<IconPlus size={16}/>} variant="outline" size="xs" onClick={addArticleModal}>
                        {t("dashboard.assistant.add-article", "Add Article")}
                    </Button>
                    <Menu shadow="md" width={200}>
                        <Menu.Target>
                            <ActionIcon variant="outline">
                                <IconDots/>
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item leftSection={<IconEdit size={16} />} onClick={editAssistantModal}>
                                {t("dashboard.assistant.edit", "Edit")}
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                                color="red"
                                onClick={deleteAssistantModal}
                                leftSection={<IconTrash size={16} />}
                            >
                                {t("dashboard.assistant.delete", "Delete")}
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Flex>
            </Flex>
            <Flex direction="column">
                <Title order={3} mb={12}>{t("dashboard.assistant.articles", "Articles")}</Title>
                <DataTable
                    fetching={articlesLoading}
                    columns={[
                        {
                            accessor: "status",
                            title: t("dashboard.assistant.status-article", "Status"),
                            width: 120,
                            textAlign: "left",
                            render: ({is_processing, has_error, is_draft_deployed}) => {
                                let statusNode = undefined
                                if (is_processing) {
                                    statusNode = <Loader size="xs" color="blue" />
                                } else if (has_error) {
                                    statusNode = <IconExclamationCircle size={18} color="red" />
                                } else if (is_draft_deployed) {
                                    statusNode = <Badge color="teal" radius="xs" variant="outline">{t("dashboard.assistant.deployed", "Deployed")}</Badge>
                                } else {
                                    statusNode = <Badge color="blue" radius="xs" variant="outline">{t("dashboard.assistant.draft", "Draft")}</Badge>
                                }
                                return statusNode
                            }
                        },
                        {
                            accessor: "title",
                            textAlign: "left",
                            title: t("dashboard.assistant.title", "Title"),
                            render: ({title}) => {
                                return (
                                    <Text size="sm" fw={500}>{title}</Text>
                                )
                            }
                        },
                        {
                            accessor: "updated_at",
                            title: t("dashboard.assistant.updated-at", "Updated at"),
                            render: ({updated_at}) => {
                                return (
                                    <Text size="sm">{updated_at == null ? "-" : formatDate(new Date(updated_at))}</Text>
                                )
                            }
                        },
                        {
                            accessor: "last_deployed_at",
                            title: t("dashboard.assistant.last-deployed", "Last deployed at"),
                            render: ({last_deployed_at}) => {
                                return (
                                    <Text size="sm">{last_deployed_at == null ? "-" : formatDate(new Date(last_deployed_at))}</Text>
                                )
                            }
                        },
                        {
                            accessor: "actions",
                            textAlign: "right",
                            title: t("dashboard.assistant.actions", "Actions"),
                            render: (article) => {
                                return (
                                    <Flex align="center" justify="flex-end" gap={6}>
                                        <ActionIcon variant="subtle" component={Link} to={"/dashboard/knowledge-base/articles/" + article.id}>
                                            <IconEdit size={14}/>
                                        </ActionIcon>
                                        <ActionIcon variant="subtle" onClick={() => openDeleteArticleModal(article)}>
                                            <IconTrash size={14}/>
                                        </ActionIcon>
                                    </Flex>
                                )
                            }
                        }
                    ]}
                    records={articles}
                    minHeight={articles.length > 0 ? 0 : 300}
                    totalRecords={totalArticles}
                    recordsPerPage={PAGE_SIZE}
                    page={articlesPage}
                    onPageChange={(p) => setArticlesPage(p)}
                />
            </Flex>
        </Flex>
    )
}

export default Assistant
