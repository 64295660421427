//
//
//  New Article Urls Form
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, TextInput} from "@mantine/core";
import {useState} from "react";
import {useTranslate} from "@tolgee/react";

function NewArticleUrlForm({onSubmit, onCancel}: {onSubmit: (values: any) => void, onCancel: () => void}){
    const {t} = useTranslate()
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            title: "",
            url: ""
        },
    })

    function onSubmitInner(values: any)  {
        setLoading(true)
        onSubmit(values)
    }

    return (
        <form onSubmit={form.onSubmit(onSubmitInner)}>
            <TextInput
                required
                withAsterisk
                label={t("dashboard.new-article.form-title", "Title")}
                placeholder="Super duper"
                {...form.getInputProps("title")}
            />
            <TextInput
                mt={12}
                label="URL"
                required
                withAsterisk
                placeholder={t("dashboard.new-article.url-input", "Enter url here")}
                {...form.getInputProps("url")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>{t("dashboard.new-article.cancel", "Cancel")}</Button>
                <Button type="submit" loading={loading}>{t("dashboard.new-article.submit", "Submit")}</Button>
            </Flex>
        </form>
    )
}

export default NewArticleUrlForm
