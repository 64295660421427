import React from 'react'
import ReactDOM from 'react-dom/client'
import '@mantine/core/styles.css'
import '@mantine/core/styles.layer.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/dropzone/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/charts/styles.css'
import '@mantine/tiptap/styles.css'
import '@mantine/notifications/styles.css'
import App from './App.tsx'
import './index.css'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import {MantineProvider} from "@mantine/core"
import {BrowserRouter} from "react-router-dom"
import {ModalsProvider} from "@mantine/modals"
import {Notifications} from "@mantine/notifications";
import {DevTools, FormatSimple, LanguageStorage, Tolgee, TolgeeProvider} from "@tolgee/react";
import * as localeEn from './locales/en.json'
import * as localeEs from './locales/es.json'

const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .use(LanguageStorage())
    .init({
        defaultLanguage: "en",

        // for development
        apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
        apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,

        // for production
        staticData: {
            en: localeEn,
            es: localeEs,
        },
        availableLanguages: ["en", "es"],
    })


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <TolgeeProvider
            tolgee={tolgee}
        >
            <MantineProvider
                defaultColorScheme="light"
                theme={{
                    primaryColor: "grape",
                }}
            >
                <Notifications/>
                <ModalsProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </ModalsProvider>
            </MantineProvider>
        </TolgeeProvider>
    </React.StrictMode>,
)
