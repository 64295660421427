//
//
//  Switch Teams Modal
//
//

import {Modal, Text, TextInput} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Team} from "../interfaces.ts";
import {API} from "../api.ts";
import {IconCircleCheckFilled} from "@tabler/icons-react";
import useAdmin from "../hooks/admin.ts";
import {useTranslate} from "@tolgee/react";

const PAGE_SIZE = 10

function SwitchTeamsModal({opened, onClose, withCloseButton = true, currentTeam, onSelect, onDeselect=() => void 0, allowDeselect = false}:
                              {opened: boolean, onClose: () => void, withCloseButton?: boolean, currentTeam?: Team, onSelect: (team: Team) => void, onDeselect?: (team: Team) => void, allowDeselect?: boolean}) {
    const isAdmin = useAdmin()
    const {t} = useTranslate()
    const [searchQuery, setSearchQuery] = useState("")
    const {getAccessTokenSilently} = useAuth0()
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [teams, setTeams] = useState<Team[]>([])
    const [loading, setLoading] = useState(true)

    function fetchTeams() {
        setLoading(true)

        if (isAdmin) {
            API.getAdminTeams(getAccessTokenSilently, searchQuery == "" ? undefined : searchQuery, page, PAGE_SIZE)
                .then((response: any) => {
                    setTeams(response["items"])
                    setTotal(response["total"])
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            API.getUserTeams(getAccessTokenSilently, searchQuery == "" ? undefined : searchQuery, page, PAGE_SIZE)
                .then((response: any) => {
                    setTeams(response["items"])
                    setTotal(response["total"])
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        fetchTeams()
    }, [getAccessTokenSilently, isAdmin])

    useEffect(() => {
        // Search when user stops typing
        const timeOutId = setTimeout(() => {
            fetchTeams()
        }, 500)
        return () => clearTimeout(timeOutId);
    }, [getAccessTokenSilently, searchQuery])


    return (
        <Modal opened={opened} onClose={onClose} title={isAdmin ? t("switch-teams.teams", "Teams") : "Your Teams"} withCloseButton={withCloseButton}>
            <TextInput
                placeholder={t("switch-teams.search-teams", "Search teams ...")}
                mb={6}
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
            <DataTable
                noHeader
                withRowBorders={false}
                verticalSpacing="sm"
                highlightOnHover={true}
                fetching={loading}
                onRowClick={({record}: {record: Team}) => {
                    if (allowDeselect && currentTeam?.id === record.id) {
                        onDeselect(record)
                    } else {
                        onSelect(record)
                    }
                }}
                columns={[
                    {
                        accessor: "name" ,
                        width: "100%",
                        render: ({name}: {name: string}) => {
                            return <Text fw={500}>{name}</Text>
                        }
                    },
                    {
                        accessor: "",
                        render: ({id}: {id: number}) => {
                            if (id === currentTeam?.id) {
                                return <IconCircleCheckFilled size={20}/>
                            }
                        }
                    }
                ]}
                records={teams}
                idAccessor="id"
                minHeight={teams.length > 0 ? 0 : 200}
                totalRecords={total}
                recordsPerPage={PAGE_SIZE}
                page={page}
                onPageChange={(p) => setPage(p)}
            />
        </Modal>
    )
}

export default SwitchTeamsModal
