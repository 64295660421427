//
//
//  Usage
//
//

import {Button, Container, Flex, Title} from "@mantine/core";
import OpenAIUsage from "./OpenAIUsage.tsx";
import {DateValue, MonthPickerInput} from "@mantine/dates";
import {useState} from "react";
import SwitchTeamsModal from "../../../components/SwitchTeamsModal.tsx";

function AdminUsage() {
    const [date, setDate] = useState<Date | DateValue>(new Date())
    const [switchTeamOpened, setSwitchTeamOpened] = useState(false)
    const [team, setTeam] = useState<any>(null)

    return (
        <>
            <Container mt={20}>
                <Flex align="center" justify="space-between" mb={50}>
                    <Title order={1}>Usage</Title>
                    <Flex gap={16} align="center">
                        <MonthPickerInput
                            value={date}
                            onChange={setDate}
                        />
                        <Button
                            variant={team == null ? "default" : "filled"}
                            onClick={() => setSwitchTeamOpened(true)}
                        >
                            {team == null ?  "Select Team" : team.name}
                        </Button>
                    </Flex>
                </Flex>
                <Title order={2} mb={24}>OpenAI</Title>
                <OpenAIUsage
                    date={date as Date}
                    team={team}
                />
            </Container>
            <SwitchTeamsModal
                opened={switchTeamOpened}
                onClose={() => setSwitchTeamOpened(false)}
                currentTeam={team}
                onSelect={(team) => setTeam(team)}
                allowDeselect={true}
                onDeselect={() => setTeam(null)}
            />
        </>
    )
}

export default AdminUsage
