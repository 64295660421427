//
//
//  New Assistant Modal
//
//

import {useForm} from "@mantine/form";
import {Button, Flex, Textarea, TextInput} from "@mantine/core";
import {useTranslate} from "@tolgee/react";

function NewAssistantModal({onSubmit, onCancel}: {onSubmit: (values: any) => void, onCancel: () => void}) {
    const {t} = useTranslate()
    const form = useForm({
        initialValues: {
            title: "",
            description: ""
        }
    })

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <TextInput
                required
                withAsterisk
                label={t("dashboard.new-article.title", "Title")}
                placeholder="Super duper"
                {...form.getInputProps("title")}
            />
            <Textarea
                mt={12}
                label={t("dashboard.new-article.description", "Description")}
                {...form.getInputProps("description")}
            />
            <Flex mt={20} gap={12} justify="flex-end">
                <Button variant="outline" onClick={onCancel}>{t("dashboard.new-article.cancel", "Cancel")}</Button>
                <Button type="submit">{t("dashboard.new-article.submit", "Submit")}</Button>
            </Flex>
        </form>
    )
}

export default NewAssistantModal
