import './App.css'
import {Navigate, Route, Routes, useNavigate} from "react-router-dom"
import RequireLoggedIn from './components/RequireLoggedIn.tsx'
import {Auth0Provider} from "@auth0/auth0-react"
import Home from "./pages/Home/index.tsx";
import Chat from "./pages/Chat/index.tsx";
import Dashboard from "./pages/Dashboard/index.tsx";
import DashboardHome from "./pages/Dashboard/Overview/index.tsx";
import DashboardConversations from "./pages/Dashboard/Conversations/index.tsx";
import DashboardConversation from "./pages/Dashboard/Conversations/Conversation/index.tsx";
import DashboardMembers from "./pages/Dashboard/Members/index.tsx";
import DashboardKnowledgeBase from "./pages/Dashboard/KnowledgeBase/index.tsx";
import DashboardKnowledgeBaseArticle from "./pages/Dashboard/KnowledgeBase/Article/index.tsx";
import Admin from "./pages/Admin/index.tsx";
import AdminUsage from "./pages/Admin/Usage/index.tsx";


function App() {
    const navigate = useNavigate()
    const onRedirectCallback = (appState: any) => {
        navigate((appState && appState.returnTo) || window.location.pathname)
    }

    return (
        <Auth0Provider
             domain={import.meta.env.VITE_AUTH0_DOMAIN}
             clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
             authorizationParams={{
                 redirect_uri: window.location.origin,
                 audience: import.meta.env.VITE_AUTH0_AUDIENCE
             }}
             onRedirectCallback={onRedirectCallback}
             cacheLocation="localstorage"
        >
            <Routes>
                <Route element={<RequireLoggedIn/>}>
                    <Route index Component={Home}/>
                    <Route path="/admin" Component={Admin}>
                        <Route index element={<Navigate replace to="/admin/usage" />}/>
                        <Route path="usage" Component={AdminUsage}/>
                    </Route>
                    <Route path="/chat" Component={Chat}/>
                    <Route path="/dashboard" Component={Dashboard}>
                        <Route index Component={DashboardHome}/>
                        <Route path="members" Component={DashboardMembers}/>
                        <Route path="knowledge-base">
                            <Route index Component={DashboardKnowledgeBase}/>
                            <Route path="articles/:articleId" Component={DashboardKnowledgeBaseArticle}/>
                        </Route>
                        <Route path="conversations">
                            <Route index Component={DashboardConversations}/>
                            <Route path=":conversationId" Component={DashboardConversation}/>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </Auth0Provider>
    )
}

export default App
