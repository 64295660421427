//
//
//  Markdown Viewer
//
//

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {a11yDark} from "react-syntax-highlighter/dist/esm/styles/prism";

function MarkdownViewer({markdown}: {markdown: string}) {
    return (
        <Markdown
            children={markdown}
            remarkPlugins={[remarkGfm]}
            components={{
                code(props) {
                    const {children, className, node} = props
                    const match = /language-(\w+)/.exec(className || '')
                    return match ? (
                        <SyntaxHighlighter
                            node={node}
                            PreTag="div"
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            style={a11yDark}
                        />
                    ) : (
                        <code className={className}>
                            {children}
                        </code>
                    )
                }
            }}
        />
    )
}

export default MarkdownViewer
