//
//
//  Assistants Selector Modal
//
//

import {Assistant as AssistantInterface} from "../../interfaces"
import {Button, Flex, TextInput, Text} from "@mantine/core";
import {useState} from "react";
import {useTranslate} from "@tolgee/react";

function AssistantsSelectorModal({assistants, onSelect}: { assistants: AssistantInterface[], onSelect: (assistant: AssistantInterface) => void }) {
    const {t} = useTranslate()
    const [viewAssistants, setViewAssistants] = useState(assistants)
    function filterAssistants(searchPrompt: string) {
        if (searchPrompt === "") {
            setViewAssistants(assistants)
        } else {
            setViewAssistants(assistants.filter((assistant: AssistantInterface) => {
                return assistant.title.toLowerCase().includes(searchPrompt.toLowerCase())
            }))
        }
    }

    return (
        <Flex direction="column">
            <TextInput
                mb={12}
                placeholder={t("assistants-selector.search", "Search assistant ...")}
                onChange={(event) => filterAssistants(event.target.value)}
            />
            <Flex direction="column" gap={12}>
              {viewAssistants.map((assistant: AssistantInterface) => {
                    return (
                        <Button key={assistant.id} fullWidth={true} variant="subtle" size="md" onClick={() => onSelect(assistant)}>
                            <Flex direction="column">
                                <Text ta="center">{assistant.title}</Text>
                            </Flex>
                        </Button>
                    )
                })}
            </Flex>
        </Flex>
    )
}

export default AssistantsSelectorModal
