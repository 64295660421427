//
//
//  Profile Menu
//
//

import {Avatar, Flex, Menu, Select, Text} from "@mantine/core";
import classes from "./ProfileMenu.module.css";
import {
    IconBrandCodesandbox,
    IconChevronRight,
    IconLogout,
    IconShieldLock,
    IconSwitchHorizontal
} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {useTeam} from "../contexts/TeamContext.tsx";
import {useState} from "react";
import SwitchTeamsModal from "./SwitchTeamsModal.tsx";
import useLogout from "../hooks/logout.ts";
import useAdmin from "../hooks/admin.ts";
import {useTolgee, useTranslate} from "@tolgee/react";

function ProfileMenu({includeSwitchTeam=true, includeDashboard, includeAdmin}: {includeSwitchTeam?: boolean, includeDashboard?: boolean, includeAdmin?: boolean}) {
    const {team, setTeam} = useTeam()
    const {t} = useTranslate()
    const tolgee = useTolgee(["language"])
    const isAdmin = useAdmin()
    const {user} = useAuth0()
    const logout = useLogout()
    const [switchTeamsOpened, setSwitchTeamsOpened] = useState(false)

    return (
        <>
            <Menu shadow="md" width={230}>
                <Menu.Target>
                    <button className={classes.navbarBtn}>
                        <Flex align="center" justify="space-between" style={{width: "100%"}}>
                            <Flex gap={10} align="center">
                                <Avatar variant="white" size="md" radius="xl" src={user?.picture}/>
                                <span>{user?.name}</span>
                            </Flex>
                            <IconChevronRight size={16} />
                        </Flex>
                    </button>
                </Menu.Target>
                <Menu.Dropdown>
                    {includeSwitchTeam && <Menu.Item leftSection={<IconSwitchHorizontal size={14}/>} onClick={() => setSwitchTeamsOpened(true)}>
                        <Flex direction="column">
                            <Text fw={500} size={"sm"}>{team.name}</Text>
                            <Text size="sm" c="dimmed">{t("menu.switch-team", "Switch team")}</Text>
                        </Flex>
                    </Menu.Item>}
                    {includeSwitchTeam && <Menu.Divider />}
                    {includeDashboard && <>
                            <Menu.Item leftSection={<IconBrandCodesandbox size={14} />} component={Link} to="/dashboard">
                                {t("menu.dashboard", "Dashboard")}
                            </Menu.Item>
                        </>
                    }
                    {includeDashboard && includeSwitchTeam && <Menu.Divider />}
                    {includeAdmin && isAdmin && <>
                            <Menu.Item leftSection={<IconShieldLock size={14} />} component={Link} to="/admin">
                                {t("menu.admin", "Admin")}
                            </Menu.Item>
                        </>
                    }
                    <Menu.Divider />
                    <Menu.Item closeMenuOnClick={false}>
                        <Text size="xs" c="dimmed" mb={4}>{t("menu.language", "Language")}</Text>
                        <Select
                            allowDeselect={false}
                            onChange={(value: any) => tolgee.changeLanguage(value)}
                            value={tolgee.getLanguage()}
                            data={[
                                {
                                    value: "en",
                                    label: t("menu.english", "English")
                                },
                                {
                                    value: "es",
                                    label: t("menu.spanish", "Spanish")
                                },
                            ]}
                        />
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item leftSection={<IconLogout size={14} />} onClick={logout}>
                        {t("menu.logout", "Logout")}
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <SwitchTeamsModal
                opened={switchTeamsOpened}
                currentTeam={team}
                onSelect={(team) => {
                    setTeam(team)
                    setSwitchTeamsOpened(false)
                }}
                onClose={() => setSwitchTeamsOpened(false)}
            />
        </>
    )
}

export default ProfileMenu
